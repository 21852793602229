import React from 'react'
import Logo from "./../../Assets/Logo.png"

function LogoContainer() {
  return (
    <div> 
      <img src={Logo} className='w-[150px] h-[150px] m-auto' />
    </div>
  )
}

export default LogoContainer
