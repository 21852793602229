import React from 'react'
import Logo from "../../Assets/LogoInCircle.png"
import Button from '../ReusableComponent/Button'

function Form({ onFormSubmit }) {
  return (
    <div className="bg-[#fafafa]  rounded-t-[20px] mt-[30px]">
      <div className="flex gap-[20px] items-center justify-center pt-[20px]">
        <div>
          <img src={Logo} className=" h-[60px] w-[60px]" alt="" />
        </div>
        <div className="font-[700] rockwell text-[12px] text-Primary">
          <h1>
            Upload <br />
            Profile Pic
          </h1>
        </div>
      </div>
      <div>
        <form
          onSubmit={onFormSubmit}
          action=""
          className=" flex flex-col  items-center mt-[30px] overflow-scroll  pb-[60px] text-[12px]  font-[700] rockwell"
        >
          <input
            title='username'
            type="text"
            placeholder="Username"
            className=" border-[1px] h-[32px] rounded-[4px] border-Primary placeholder:text-Primary-0.5 placeholder:text-center"
          />{" "}
          <br />
          <input
            title='full_name'
            type="text"
            placeholder="Full Name"
            className=" border-[1px] h-[32px] rounded-[4px] border-Primary placeholder:text-Primary-0.5 placeholder:text-center"
          />
          <br />
          <input
            title='email'
            type="text"
            placeholder="Email"
            className=" border-[1px] h-[32px] rounded-[4px] border-Primary placeholder:text-Primary-0.5 placeholder:text-center"
          />
          <br />
          <input
            title="phone_number"
            type="text"
            placeholder="Phone Number"
            className=" border-[1px] h-[32px] rounded-[4px] border-Primary placeholder:text-Primary-0.5 placeholder:text-center"
          />
          <br />
          <input
            title='nationality'
            type="text"
            placeholder="Nationality"
            className=" border-[1px] h-[32px] rounded-[4px] border-Primary placeholder:text-Primary-0.5 placeholder:text-center"
          />
          <br />
          <input
            title='date_of_birth'
            type="date"
            placeholder="Date of Birth"
            className=" border-[1px] h-[32px] rounded-[4px] border-Primary placeholder:text-Primary-0.5 placeholder:text-center"
          />
          <br />
          <input
            title='password'
            type="password"
            placeholder="Password"
            className=" border-[1px] h-[32px] rounded-[4px] border-Primary placeholder:text-Primary-0.5 placeholder:text-center"
          />
          <br />
          <Button
            text="JOIN CHAMPION FAN"
            classname="bg-Secondary px-[22px] pt-[10px] bebas pb-[5px] rounded-[8px] font-[500] text-Primary mb-[20px]"
          />
        </form>
      </div>
    </div>
  );
}

export default Form
