import React, { useEffect, useState } from "react";
import Button from "../ReusableComponent/Button";
import { Link, useParams } from "react-router-dom";
import { featuredPool } from "../../Api";

function TextSection() {
  const { tournament_id } = useParams();

  const [poolDetail, setPoolDetail] = useState({
    poolName: "Loading..",
    sports: "",
    events: "",
    duration: "",
    lastGame: "",
    numberOfPlayers: 0,
    entry: "Free",
  });

  const [authToken, setAuthToken] = useState(
    localStorage.getItem("sessionToken")
  );

  useEffect(() => {
    async function collectData() {
      const featuredPoolResponse = await featuredPool(authToken);
      if (featuredPoolResponse.success) {
        console.log(tournament_id);
        const currentPool = featuredPoolResponse.response.filter(
          (pool) => pool["tournament_id"] == tournament_id
        );
        console.log("featuredPoolResponse pool", featuredPoolResponse.response);
        console.log("current pool", currentPool);
        if (currentPool.length > 0)
          setPoolDetail({
            poolName: currentPool[0].name,
            sports: currentPool[0].sport || "Soccer",
            events: currentPool[0].league.title,
            duration: currentPool[0].start_time,
            lastGame: currentPool[0].end_time,
            numberOfPlayers: currentPool[0].player_count,
            entry: "Free",
          });
      } else {
        console.log("No Pool Fetched");
        alert("Cannot Get Pool Details");
      }
    }
    collectData();
  }, [authToken, tournament_id]);

  return (
    <div className="flex flex-col gap-[20px] text-center items-center justify-center">
      <div>
        <h1 className="text-[32px] text-[#043a0e] bebas font-[800] pt-[40px] mb-[10px]">
          {poolDetail.poolName}
        </h1>
      </div>
      <div>
        <h1 className="text-[20px] text-[#043a0e] font-[500] bebas">SPORTS</h1>
        <p className="font-[400] text-[10px] rockwell">{poolDetail.sports}</p>
      </div>
      <div>
        <h1 className="text-[20px] text-[#043a0e] font-[500] bebas">SPORTS</h1>
        <p className="font-[400] text-[10px] rockwell">{poolDetail.events}</p>
      </div>
      <div>
        <h1 className="text-[20px] text-[#043a0e] font-[500] bebas">
          Duration
        </h1>
        <p className="font-[400] text-[10px] rockwell">
          Tournment will start on {poolDetail.duration}
        </p>
        <p className="font-[400] text-[10px] rockwell">
          Last game will be on the {poolDetail.lastGame}
        </p>
      </div>
      <div>
        <h1 className="text-[20px] text-[#043a0e] font-[500] bebas">
          NUMBERS FO PLAYERS
        </h1>
        <p className="font-[400] text-[10px] rockwell">
          {poolDetail.numberOfPlayers} Fans have registered so far
        </p>
      </div>
      <div>
        <h1 className="text-[20px] text-[#043a0e] font-[500] bebas">Entry</h1>
        <p className="font-[400] text-[10px] rockwell">Free</p>
      </div>
      <Link to={`/Join/JoinPool/${tournament_id}/Select`}>
        <Button
          text="Join Pool"
          classname="bg-Secondary px-[22px] pt-[5px] pb-[5px] mb-[60px] mt-[20px] rockwell rounded-[5px] text-[12px] font-[400]"
        />
      </Link>
    </div>
  );
}

export default TextSection;
