import React from 'react'
import {Scoredata2} from "../data"

function PoolName({poolName="Test Tournament"}) {
  return (
    <div>
      <div>
      <h1 className='text-[26px] font-[400] text-center bebas pt-[14px]'>POOL NAME</h1>
      </div>

      <div className="bg-white shadow-lg rounded-[10px] py-[14px] p-[3px] my-[20px] mx-[20px]">
          <h1 className="text-center text-[24] mb-[10px] rockwell font-[700]">Leaderboard</h1>
          <table className="w-full whitespace-nowrap  mt-[20px]">
            {Scoredata2.map((itmes) => (
              <tr className="text-sm rockwell font-regular border ">
                <td className="px-4 py-[2px]">{itmes.Number}</td>
                <td className="px-4 py-[2px]">{itmes.Username}</td>
                <td className="px-4 py-[2px]">{itmes.ScorePoint}</td>
              </tr>
            ))}
          </table>
        </div>
    </div>
  )
}

export default PoolName
