import React from 'react'
import LogoSection from "../CreatePoolConfirmation/LogoSection"
import PoolName from "../CreatePoolConfirmation/PoolName"
import Footer from "../ReusableComponent/Footer"

function CreatePoolConfirmation() {
  return (
    <div className='background'>
       <LogoSection />
      <div className="bg-white rounded-t-[20px] mt-[50px] pb-[30px]">
        <PoolName />
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default CreatePoolConfirmation
