import React from 'react'
import Logo from "../../Assets/Logo.png";
import Button from "../ReusableComponent/Button";

function LogoSection() {
  return (
    <div>
       <div className="flex justify-between items-center">
        <img src={Logo} alt="" className="h-[50px] w-[50px] p-[10px]" />
        <Button  text='Connect Wallet'classname='bg-Secondary px-[18px] pb-[4px] pt-[8px] rockwell font-[400] rounded-[5px] mr-[10px]' />
      </div>
      <div>
        <img src={Logo}   className='h-[60px] w-[60px] m-auto mt-[30px]' />
      </div>
    </div>
  )
}

export default LogoSection
