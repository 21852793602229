import React from "react";

function BottomContent() {
  return (
    <div className=" mt-[20px] flex justify-center mb-[80px]">
      <div className=" h-[90px] w-[88%] bg-Primary flex items-center justify-center">
        <h1 className="font-[400] text-[20px] text-Secondary bebas">
          NEWS/BRANDING/BEST BETS/LINKS
        </h1>
      </div>
    </div>
  );
}

export default BottomContent;
