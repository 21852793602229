import React, { useState } from 'react'
import { Checkbox } from '@headlessui/react'

function TickComponent({className}) {
    const [enabled, setEnabled] = useState(false)
  return (
    <div>
      <Checkbox
      checked={enabled}
      onChange={setEnabled}
      className={`group block size-4 rounded border ${className}, data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 data-[checked]:data-[disabled]:bg-gray-500`}>
      <svg className="stroke-white opacity-0 group-data-[checked]:opacity-100" viewBox="0 0 14 14" fill="none">
        <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Checkbox>
    </div>
  )
}

export default TickComponent
