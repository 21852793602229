import React, { useEffect, useState } from "react";
import SquareContainer from "../ReusableComponent/SquareContainer";
import { getSports } from "../../Api";

function Sports({unclickable = false}) {
  
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("sessionToken")
  );
  const [sports, setSports] = useState([]);

  useEffect(() => {
    async function collectData() {
      const sportsResponse = await getSports();
      if (sportsResponse.success) {
        setSports(sportsResponse.response);
      } else {
        console.log("No Sports Fetched");
        // alert("Could Not Fetch Sports!");
      }


    }
    collectData();
  }, [authToken]);

  return (
    <div className="">
      <h1 className="text-[24px] font-[400] bebas  mt-[25px] ml-[10px]">
        SPORTS
      </h1>
      <div className="flex text-[12px] items-center gap-[10px] mt-[10px] overflow-x-scroll ">
        {sports.map((s) => {
          return (
            <SquareContainer
              unclickable={unclickable}
              text={String(s.title).toUpperCase()}
              className="text-white !bg-Primary"
            />
          );
        })}
        {/* <SquareContainer text=" SOCCER" className="text-Primary " /> */}
        {/* <SquareContainer text=" RUGBY" className="text-white !bg-Primary" />
        <SquareContainer text=" GOLF" className="text-white !bg-Primary " />
        <SquareContainer
          text=" BASEBALL"
          className="text-white !bg-Primary  "
        />
        <SquareContainer
          text=" BASKETBALL"
          className="text-white !bg-Primary "
        /> */}
      </div>
    </div>
  );
}

export default Sports;
