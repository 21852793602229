import { FaPlus, FaUsers, FaUser } from "react-icons/fa";
import { IoMenuSharp } from "react-icons/io5";
import Logo from "./../Assets/Logo.png";
import Germany from "../Assets/Germany.png";
import Scotland from "../Assets/Scotland.png";
import Hungry from "../Assets/Hungry.png";
import Switzerland from "../Assets/switzerland.png";
import Spain from "../Assets/spain.png";
import Coratia from "../Assets/croatia.png";
import Italy from "../Assets/Italy.png";
import Albania from "../Assets/Albania.png";
import Solvenia from "../Assets/slovenija.png";
import Denmark from "../Assets/denmark.png";
import Serbia from "../Assets/Serbia.png";
import England from "../Assets/england.png";
import TickComponent from "./ReusableComponent/TickComponent";


export const data = [
  {
    icon: <FaPlus className={`cursor-pointer`} />,
    text: "CREATE",
    Link: "/Create",
    activeicon: "plus",
  },
  {
    icon: <FaUsers className="cursor-pointer"  />,
    text: "JOIN",
    Link: "/Join",
  },
  {
    icon: <img src={Logo} className="h-[24px] w-[24px] cursor-pointer"  />,
    text: "HOME",
    Link: "/Homepage",
  },
  {
    icon: <FaUser className="cursor-pointer" />,
    text: "PROFILE",
    Link: "/Profile",
  },
  {
    icon: <IoMenuSharp className="cursor-pointer" />,
    text: "MENUE",
  },
];

export const Scoredata = [
  {
    Number: "1",
    Username: "Username",
    ScorePoint: "109",
  },
  {
    Number: "2",
    Username: "Username",
    ScorePoint: "105",
  },

  {
    Number: "3",
    Username: "Username",
    ScorePoint: "98",
  },

  {
    Number: "4",
    Username: "Username",
    ScorePoint: "89",
  },
  {
    Number: "5",
    Username: "Username",
    ScorePoint: "78",
  },
];

export const Scoredata2 = [
  {
    Number: "1",
    Username: "Username",
    ScorePoint: "109",
  },
  {
    Number: "2",
    Username: "Username",
    ScorePoint: "105",
  },

  {
    Number: "3",
    Username: "Username",
    ScorePoint: "98",
  },

  {
    Number: "4",
    Username: "Username",
    ScorePoint: "89",
  },
  {
    Number: "5",
    Username: "Username",
    ScorePoint: "78",
  },
  {
    Number: "6",
    Username: "Username",
    ScorePoint: "68",
  },
  {
    Number: "7",
    Username: "Username",
    ScorePoint: "66",
  },
  {
    Number: "9",
    Username: "Username",
    ScorePoint: "62",
  },
];

export const matchschedule = [
  {
    HomeImage: <img src={Germany} />,
    HomeCountry: "Germany",
    AwayImage: <img src={Scotland} />,
    AwayCountry: "Scotland",
  },
  {
    HomeImage: <img src={Hungry} />,
    HomeCountry: "Hungry",
    AwayImage: <img src={Switzerland} />,
    AwayCountry: "Switzerland",
  },
  {
    HomeImage: <img src={Spain} />,
    HomeCountry: "Spain",
    AwayImage: <img src={Coratia} />,
    AwayCountry: "Coratia",
  },
  {
    HomeImage: <img src={Italy} />,
    HomeCountry: "Italy",
    AwayImage: <img src={Albania} />,
    AwayCountry: "Albania",
  },
  {
    HomeImage: <img src={Solvenia} />,
    HomeCountry: "Solvenia",
    AwayImage: <img src={Denmark} />,
    AwayCountry: "Denmark",
  },
  {
    HomeImage: <img src={Serbia} />,
    HomeCountry: "Serbia",
    AwayImage: <img src={England} />,
    AwayCountry: "England",
  },
];

export const event = [
  {
    SoccerPool: "Pies&Gravey",
    date: "24 Jan 25",
    players: "302",
    entry: "Free",
  },
  {
    SoccerPool: "Good Will",
    date: "31 Jan 25",
    players: "77",
    entry: "2 Sol",
  },
  {
    SoccerPool: "Steves League",
    date: "2 Feb 25",
    players: "575",
    entry: "1 ETH",
  },
  {
    SoccerPool: "Pies&Gravey",
    date: "24 Jan 25",
    players: "302",
    entry: "Free",
  },
  {
    SoccerPool: "Good Will",
    date: "31 Jan 25",
    players: "77",
    entry: "2 Sol",
  },
  {
    SoccerPool: "Steves League",
    date: "2 Feb 25",
    players: "575",
    entry: "1 ETH",
  },
];

export const event2 = [
  {
    Soccer: "Premier League",
    date: "24 Jan 25",
    time: "302",
    checkbox: <TickComponent  className="bg-Secondary data-[checked]:border data-[checked]:border-white "/>,
  },
  {
    Soccer: "La liga",
    date: "31 Jan 25",
    time: "77",
    checkbox: <TickComponent  className="bg-Primary border border-white data-[checked]:bg-Secondary "/>,
  },
  {
    Soccer: "Europa",
    date: "2 Feb 25",
    time: "575",
    checkbox: <TickComponent  className="bg-Primary border border-white data-[checked]:bg-Secondary "/>,
  },
  {
    Soccer: "PSL",
    date: "24 Jan 25",
    time: "302",
    checkbox: <TickComponent  className="bg-Primary border border-white data-[checked]:bg-Secondary "/>,
  },
  {
    Soccer: "Chili League 1",
    date: "31 Jan 25",
    time: "77",
    checkbox: <TickComponent  className="bg-Primary border border-white data-[checked]:bg-Secondary "/>,
  },
  {
    Soccer: "Champions League",
    date: "2 Feb 25",
    time: "575",
    checkbox: <TickComponent  className="bg-Primary border border-white data-[checked]:bg-Secondary "/>,
  },
];
