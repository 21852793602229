import React from "react";
import Logo from "../../Assets/Logo.png";
import Button from "../ReusableComponent/Button";

function Header() {
  return (
    <div>
      <div>
        <img src={Logo} alt="" className="h-[50px] w-[50px] p-[10px]" />
      </div>
      <div>
        <img src={Logo} className="h-[60px] w-[60px] m-auto mt-[30px]" />
      </div>
      <h1 className="text-[24px] font-bold text-white text-center mt-[20px]">Create Pool</h1>
    </div>
  );
}

export default Header;
