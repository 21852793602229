import React from "react";
import HeaderContainer from "../RegisterPage/HeaderContainer";
import Form from "../RegisterPage/Form";
import Footer from "../ReusableComponent/Footer";

import { registerUser } from "../../Api";
import { useNavigate } from "react-router-dom";

function RegisterPage() {


  const navigate = useNavigate();

  //do axios request
  const onFormSubmit = async(event) => {
    event.preventDefault();
    const formData = {};
    Array.from(event.target).map((t)=>{
      formData[t.title] = t.value
    })
    [formData["first_name"], formData["last_name"]] = formData.full_name.split(" ");
    const result = await registerUser(formData);

    if (result.sucess) {
      navigate("/Login");
      alert("Registration Successful");
      
    }
    else {
      alert("Registration Failed");
      console.log(result.error);
    }


  }




  return (
    <div className="background1 mb-[30px]">
      <HeaderContainer />
      <Form onFormSubmit={onFormSubmit} />
    </div>
  );
}

export default RegisterPage;
