import React, { useEffect, useState } from "react";
import { FaUser, FaPlus, FaBars, FaUserPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Logo.png";

const Footer = () => {
  const [activeIcon, setActiveIcon] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  //use localstorage to check 
  let sessionToken = localStorage.getItem("sessionToken");

  useEffect(()=>{
    if(sessionToken)
      setLoggedIn(true);
    else 
      setLoggedIn(false);
  },[sessionToken])

  const handleIconClick = (icon) => {
    const sessionToken = localStorage.getItem("sessionToken");
    console.log("season token", sessionToken);
    if (sessionToken) {
      setActiveIcon(icon);
    }

  };

  return (
    <div className="fixed bottom-0 w-full flex max-w-[425px] rockwell text-[9px] justify-around bg-Primary py-2 shadow-lg">
      <div className="flex flex-col items-center">
        <Link to={loggedIn ? "/Create" : "/Login"}>
          <FaPlus
            className={`text-white text-2xl cursor-pointer transition-colors ${
              activeIcon === "plus" ? "text-yellow-500" : ""
            }`}
            onClick={() => handleIconClick("plus")}
          />
        </Link>
        <span className="text-white text-xxs">Create</span>
      </div>
      <div className="flex flex-col items-center">
        <Link to={loggedIn ? "/Join" : "/Login"}>
          <FaUserPlus
            className={`text-white text-2xl cursor-pointer transition-colors ${
              activeIcon === "join" ? "text-yellow-500" : ""
            }`}
            onClick={() => handleIconClick("join")}
          />
        </Link>
        <span className="text-white text-xxs">Join</span>
      </div>

      <div className="flex flex-col items-center">
        <Link to={loggedIn ? "/Homepage" : "/Login"} >
          <img
            src={Logo}
            alt="Home"
            className={`h-6 cursor-pointer transition-transform ${
              activeIcon === "home" ? "transform scale-110" : ""
            }`}
            onClick={() => handleIconClick("home")}
          />
        </Link>
        <span className="text-white text-xxs">Home</span>
      </div>
      <div className="flex flex-col items-center">
        <Link to={loggedIn ? "/Profile" : "/Login"} >
          <FaUser
            className={`text-white text-2xl cursor-pointer transition-colors ${
              activeIcon === "user" ? "text-yellow-500" : ""
            }`}
            onClick={() => handleIconClick("user")}
          />
        </Link>
        <span className="text-white text-xxs">Profile</span>
      </div>
      <div className="flex flex-col items-center">
        <FaBars
          className={`text-white text-2xl cursor-pointer transition-colors ${
            activeIcon === "menu" ? "text-yellow-500" : ""
          }`}
          onClick={() => handleIconClick("menu")}
        />
        <span className="text-white text-xxPs">Menu</span>
      </div>
    </div>
  );
};

export default Footer;
