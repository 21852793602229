import React, { useEffect, useState } from "react";
import { loginUser } from "../../Api";
import { useNavigate } from "react-router-dom";

function Login() {

  const [loggedIn, setLoggedIn] = useState();
  const sessionToken = localStorage.getItem("sessionToken");

  const navigate = useNavigate()
  useEffect(()=>{
    if(sessionToken){
      setLoggedIn(true);
    }
    else{
      setLoggedIn(false);
    }
  },[sessionToken])

  useEffect(()=>{
    if(loggedIn){
      navigate("/Create");
    }
  }, [loggedIn])

  const onLoginSubmit = async(event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;

    const loginDetails = {
      email,
      password
    }
    const response = await loginUser(loginDetails);
    console.log("response", response);

    if(response.sucess){
      localStorage.setItem("sessionToken", response.response.token)
      navigate("/Create")
    }
    else{
      alert("Login Failed");
    }

  }



  return (
    <div className="background h-screen flex flex-col items-center justify-center">
      <div className="">
        <form
         onSubmit={onLoginSubmit}
          action=""    
          className="bg-slate-200 rounded-[10px] px-[4rem] py-[2rem] flex flex-col gap-[1.4rem] "
              >
          <input
            type="email"
            name="email"
            placeholder="Email"
            title="email"
            className="rounded-[4px] h-[2rem] placeholder:text-center"
          /> 
          {/* <br /> <br /> */}
          <input
            type="password"
            name="password"
            placeholder="Password"
            title="password"
            className="rounded-[4px] h-[2rem] placeholder:text-center"
          />  
          {/* <br /> <br /> */}
          <input
            type="submit"
            value=" Login"
            title="login"
            className="bg-Secondary rounded-[10px] cursor-pointer py-[7px] font-[500]"
          />
        </form>
      </div>
    </div>
  );
}

export default Login;
