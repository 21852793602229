import React from "react";
import Button from "../ReusableComponent/Button";
import { Link } from "react-router-dom";

function PoolName() {
  return (
    <div className="flex flex-col gap-[30px] text-center items-center justify-center">
      <div>
        <h1 className="text-[28px] bebas font-[700] pt-[20px] mb-[10px]">
          POOL NAME
        </h1>
      </div>
      <div>
        <h1 className="text-[20px] font-[700] bebas">SPORTS</h1>
        <p className="font-[400] text-[10px] rockwell">Soccet</p>
      </div>
      <div>
        <h1 className="text-[20px] font-[700] bebas">EVENTS</h1>
        <p className="font-[400] text-[10px] rockwell">
          English premier league 24/25
        </p>
      </div>
      <div>
        <h1 className="text-[20px] font-[700] bebas">Duration</h1>
        <p className="font-[400] text-[10px] rockwell">
          Tournment will start on 25 july 2024
        </p>
        <p className="font-[400] text-[10px] rockwell">
          Last game will be on the 28 August 2024
        </p>
      </div>
      <div>
        <h1 className="text-[20px] font-[700] bebas">NUMBERS FO PLAYERS</h1>
        <p className="font-[400] text-[10px] rockwell">
          300 Fans have registered so far
        </p>
      </div>
      <div>
        <h1 className="text-[20px] font-[700] bebas">Entry</h1>
        <p className="font-[400] text-[10px] rockwell">Free</p>
      </div>
      <div>
        <h1 className="text-[20px] font-[700]">POOL ID</h1>
        <p className="font-[400] text-[10px]">ahaasfh887a8f6ar4654sd</p>
      </div>
      <Link to="/Profile">
        <Button
          text="Join Pool"
          classname="bg-Secondary px-[18px] pt-[8px] pb-[4px] mb-[60px] rockwell rounded-[5px]  font-[500]"
        />
      </Link>
    </div>
  );
}

export default PoolName;
