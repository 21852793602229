import React, { useEffect, useState } from "react";
import ReusableTrournmentContainer from "../ReusableComponent/ReusableTrournmentContainer";
import { allTournamentPicks, getUserDetails } from "../../Api";

function Tournments() {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("sessionToken")
  );

  const [username, setUsername] = useState("");
  const [currentTournament, setCurrentTournament] = useState([]);
  const [players, setCurrentPlayers] = useState({});

  useEffect(() => {
    const tournaments = async () => {
      let userName = await getUserDetails(authToken);
      if (userName.success) {
        userName = userName.response.username;
        setUsername(userName);
      }

      const allTournaments = await allTournamentPicks();
      if (allTournaments.sucess) {
        console.log("all tournaments", allTournaments.data);
        let currentUserTournments = allTournaments.data.filter(
          (tournament) => tournament.player.username === userName
        );
        console.log("current user tournament", currentUserTournments);
        //got all the current tournament
        setCurrentTournament(currentUserTournments);

        //now get all the current leaderboard
        let tournamentPlayers = {};
        allTournaments.data.map((tournament) => {
          if (!tournamentPlayers[tournament.tournament.id]) {
            tournamentPlayers[tournament.tournament.id] = {
              players: [],
            };
          }
          tournamentPlayers[tournament.tournament.id].players.push(
            tournament.player
          );
        });

        console.log("current tournament players", tournamentPlayers);
        setCurrentPlayers(tournamentPlayers);
      } else {
        alert("Couldn't fetch", allTournaments.error);
      }
    };

    tournaments();
  }, [authToken, username]);

  return (
    <div>
      <div className="px-[5px] pb-[25px] mb-[60px]">
        <h1 className="font-[400] text-center text-[26px] bebas p-[20px]">
          Current Tournments
        </h1>
        <div className="flex flex-col gap-[30px]">
          {currentTournament.map((currentTournament) => (
            <ReusableTrournmentContainer
              name={currentTournament.tournament.name}
              players={players[currentTournament.tournament.id].players}
              tournamentId={currentTournament.tournament.tournament_id}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Tournments;
