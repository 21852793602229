import React, { useEffect, useState } from "react";
import ReausableUsernameContainer from "../ReusableComponent/ReausableUsernameContainer";
import PoolName from "../TournmentPreview/PoolName";
import MakeYourPick from "../TournmentPreview/MakeYourPick";
import Footer from "../ReusableComponent/Footer";
import { featuredPool } from "../../Api";
import { useParams } from "react-router-dom";

function Tournment() {
  const { tournament_id } = useParams();

  const [authToken, setAuthToken] = useState(
    localStorage.getItem("sessionToken")
  );

  const [poolDetails, setPoolDetail] = useState({});


  useEffect(() => {
    async function collectData() {
      const featuredPoolResponse = await featuredPool(authToken);
      console.log("tournament id", tournament_id)      
      if (featuredPoolResponse.success) {
        const currentPool = featuredPoolResponse.response.filter(
          (pool) => pool["tournament_id"] == tournament_id
        );
        console.log("featuredPoolResponse pool", featuredPoolResponse.response);
        console.log("current pool", currentPool);
        if (currentPool.length > 0)
          setPoolDetail(
            currentPool[0]
          );
      } else {
        console.log("No Pool Fetched");
      }
    }
    collectData();
  }, [authToken, tournament_id]);

  return (
    <div className="background1">
      <ReausableUsernameContainer />
      <div className="bg-[#E6E6E6] rounded-t-[16px] mt-[50px]">
        <PoolName poolName={poolDetails.name} />
        <MakeYourPick poolDetails={poolDetails} />
      </div>
    </div>
  );
}

export default Tournment;
