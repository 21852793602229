import React, { useEffect, useState } from "react";
import Header from "../CreatePool/Header";
import InputField from "../CreatePool/InputeField";
import Sports from "../CreatePool/Sports";
import TimeFrame from "../CreatePool/TimeFrame";
import Event from "../CreatePool/Event";
import { convertTimestampToDateTime, createTournamentPool, getEvents, getLeagues, getSports } from "../../Api";
import Button from "../ReusableComponent/Button";
import { useNavigate } from "react-router-dom";

function CreatePool() {
  const [authToken, setAuthToken] = useState(localStorage.getItem("sessionToken"))
  const [championSport, setSports] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [events, setEvents] = useState([]);
  const [poolId, setPoolId] = useState(new Date().getTime())

  const navigate = useNavigate();

  //
  const [pool, setPool] = useState({
    poolName: "",
    entryType: "",
    sports: [],
    timeFrame: {
      startDate: "",
      endDate: "",
    },
    leagues: [],
  });

  const leaguesUpdated = (leagues) => {
    console.log("leagues updated", leagues);
    //convert the map to array
    let newLeagues = Object.keys(leagues).filter((key) => leagues[key]);
    setPool({
      ...pool,
      leagues: newLeagues
    });
  }

  useEffect(()=>{
    console.log("pool", pool);
    let newEvents = [];
    if(pool.leagues != [] && pool.timeFrame.startDate != " " && pool.timeFrame.endDate != " "){
      pool.leagues.map(async (league) => {
        const eventsResponse = await getEvents({
          startDateTime: pool.timeFrame.startDate,
          endDateTime: pool.timeFrame.endDate,
          leagueId: league,
        });
        if (eventsResponse.success) {
          newEvents = newEvents.concat(eventsResponse.response);
          setEvents(newEvents);

        } else {
          console.log("No Events Fetched")
          // alert("Could Not Fetch Events!");
        }
      });

    }

  }, [pool])

  const timeFrameUpdate = (timeFrame) => {  
    const startTime=new Date();
    let endTime=new Date();
  
    switch (timeFrame) {
      case "entire":
        //same
        endTime = endTime.setHours(3000);
      break;
      case "today":
        endTime = endTime.setHours(24,0,0,0);
      break;  
      case "week":
        endTime = endTime.setHours(24*7, 0, 0, 0);
      break;
      case "month":
        endTime = endTime.setMonth(1, 0);
      break;
      default:
        // Handle the default case here
        break;
    }
    console.log("time frame updated", startTime, endTime);
    setPool({
      ...pool,
      timeFrame: {
        startDate: startTime.getTime(),
        endDate: endTime,
      },
    });
  }

  const startTimeUpdated = (value) => {
    console.log("start time updated", value);
    let startDate = new Date(value.target.value)
    startDate = startDate.getTime()
    setPool({
      ...pool,
      timeFrame: {
        startDate,
        ...pool.timeFrame,
      },
    });
  }

  const endTimeUpdated = (value) => {
    console.log("end time updated", value);
    let endDate = new Date(value.target.value)
    endDate = endDate.getTime()
    setPool({
      ...pool,
      timeFrame: {
        ...pool.timeFrame,
        endDate,
      },
    });
  }

  const poolNameUpdated = (value) => {
    console.log("pool name updated", value);
    setPool({
      ...pool,
      poolName: value,
    });
  
  }

  const typeUpdated = (value) => {
    console.log("type updated", value);
    setPool({
      ...pool,
      entryType: value,
    });
  }

  useEffect(() => {
    const sports = async () => {
      const sportResponse = await getSports();

      if (sportResponse.success) {
        setSports(sportResponse.response);
      } else {
        alert("Could Not Fetch Sports!");
      }

      const leaguesResponse = await getLeagues();
      if(leaguesResponse.success){
        setLeagues(leaguesResponse.response);
      }else{
        alert("Could Not Fetch Leagues!");
      }

    };
    sports();
    timeFrameUpdate("entire");
  }, []);

  
  const createPool = async() => {

    console.log("pool", pool);
    const poolDate = {
      "tournament_id": poolId,
      "name": pool.poolName,
      "start_time": convertTimestampToDateTime(pool.timeFrame.startDate, "T"),
      "end_time": convertTimestampToDateTime(pool.timeFrame.endDate, "T"),
      "entry_fee": 1,
      "league": pool.leagues[0],
      "sport_event": events.map(event=> event.id),
      "tournament_type": "O"
    }

    const createResponse = await createTournamentPool(authToken, poolDate);

    if(createResponse.sucess){
      alert("Pool Created Successfully!");
      navigate("/Homepage")
    }
    else {
      alert("Pool Creation Failed",)
    }

  }


  return (
    <div className="background1">
      <Header />
      <div className="bg-white pt-[5px] mt-[30px] rounded-[20px] pb-[30px]">
        <InputField
          typeUpdated={typeUpdated}
          poolNameUpdated={poolNameUpdated}
        />
        <Sports championSport={championSport} unclickable={true} />
        <TimeFrame
          timeframeUpdate={timeFrameUpdate}
          startTimeUpdated={startTimeUpdated}
          endTimeUpdated={endTimeUpdated}
        />
        <Event
          leagues={leagues}
          events={events}
          leaguesUpdated={leaguesUpdated}
          poolId={poolId}
        />
        <Button
          onClick={()=>{
            if(pool.poolName == ""){
              alert("Please Enter Pool Name")
              return
            }
            if(events.length > 0){
              createPool()
            }  
            else{
              alert("No Events, Please Select Different Time Range or League")
            }
          
          }}
          text="Create Pool"
          classname="bg-Secondary px-[18px] pb-[4px] pt-[8px] text-[12px] rockwell font-[400] rounded-[8px] mb-[80px]"
        />
      </div>
    </div>
  );
}

export default CreatePool;
