import "./App.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./Component/Pages/LandingPage";
import CfHomePage from "./Component/Pages/CfMainHomePage";
import JoinPool from "./Component/Pages/JoinPool";
import CreatePool from "./Component/Pages/CreatePool";
import CurrentTournment from "./Component/Pages/CurrentTournment";
import JoinPoolConfirmation from "./Component/Pages/JoinPoolConfirmation";
import Tournment from "./Component/Pages/Tournment";
import CreatePoolConfirmation from "./Component/Pages/CreatePoolConfirmation";
import Footer from "./Component/ReusableComponent/Footer";
import Register from "./Component/Pages/RegisterPage";
import Login from "./Component/Pages/Login";
import NavButtons from "./Component/JoinPool/NavButtons";
import SportsSelector from "./Component/Pages/SportsSelector";

function App() {
  return (
    <div className="App  max-w-[425px] m-auto">
      <BrowserRouter>
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path="/Homepage" element={<CfHomePage />} />
          <Route path="/Join" element={<JoinPool />} />
          <Route path="/Create" element={<CreatePool />} />
          <Route path="/Create/Joinpool" element={<CreatePoolConfirmation />} />
          <Route path="Homepage/Profile" element={<CurrentTournment />} />
          <Route path="Homepage/Joinpool/" element={<JoinPoolConfirmation />} />
          <Route path="Join/Joinpool/:tournament_id" element={<JoinPoolConfirmation />} />
          <Route path="Join/Joinpool/:tournament_id/Select" element={<Tournment/>} />
          <Route path="/profile" element={<CurrentTournment />} />
          {/* <Route path="/TournmentPage" element={<Tournment />} /> */}
          <Route path="/Register" element={<Register />} />
          <Route path="/Login" element={<Login />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
