import React, { useEffect, useState } from "react";
import FeaturedPools from "../CfHomePage/FeaturedPools";
import Buttons from "../CfHomePage/Buttons";
import BottomContainer from "../CfHomePage/BottomContent";
import Footer from "../ReusableComponent/Footer";
import { featuredPool, getSports } from "../../Api";
import Sports from "../CreatePool/Sports";
import ReausableUsernameContainer from "../ReusableComponent/ReausableUsernameContainer";
import HeaderContainer from "../JoinPool/HeaderContainer";
import NavButtons from "../JoinPool/NavButtons";

function CfMainHomePage() {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("sessionToken")
  );
  const [sports, setSports] = useState([])
  const [pools, setPools] = useState([])

  useEffect(() => {
    async function collectData(){
      const sportsResponse = await getSports();
      const featuredPoolResponse = await featuredPool(authToken);
      if(sportsResponse.success){
        setSports(sportsResponse.response);
      }
      else{
        console.log("No Sports Fetched");
        // alert("Could Not Fetch Sports!");
      }

      if (featuredPoolResponse.success) {
        setPools(featuredPoolResponse.response);
      } else {
        console.log("No Pool Fetched");
      }
    }
    collectData()
  }, [authToken])

  return (
    <div className="background2">
      <NavButtons />
      <ReausableUsernameContainer />
      <div className="px-[10px] bg-white mt-[40px] rounded-t-[20px] pb-[23px]">
        <Buttons />
        <FeaturedPools />
        <Sports unclickable={true}/>
        <BottomContainer />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default CfMainHomePage;
