import React, { useEffect } from "react";
import { useState } from "react";
import Button from "../ReusableComponent/Button";
import { Link } from "react-router-dom";
import { Checkbox } from "@headlessui/react";
import { emptyfunc } from "./TimeFrame";

function Event({leagues, events=[], leaguesUpdated, poolId}) {

  const [selectedLeagues , setSelectedLeagues] = useState({});
  const [leagueId, setLeagueId] = useState(Date.now())

  return (
    <div className=" mx-[10px] mt-[10px]">
      <h1 className="font-bold text-[24px] ml-[10px]">League</h1>
      <table className="w-[100%] whitespace-nowrap rounded-[16px] text-white rockwell m-auto mt-[1rem] bg-Primary ">
        <thead className=" text-[15px] font-medium">
          <tr className="rounded-lg font-[400] text-[12px] ">
            <th className="px-4 py-3.5 text-start">Soccer </th>
            {/* <th className="px-4 py-3.5 text-start">Start Date</th> */}
            <th className="px-4 py-3.5 text-start">Duration</th>
            <th className="px-4 py-3.5 text-start">Select</th>
          </tr>
        </thead>
        {leagues.map((some) => (
          <tr key={some.id}>
            <td className="px-4 py-2 items-center flex gap-[10px] text-[9px] font-[700]">
              {some.title}
            </td>
             {/* <td className="px-3 py-2 font-[400] text-[9px] text-center"> 
              4 Weeks
            </td>  */}
           <td className="px-3 py-2  font-[400] text-[9px] text-center">
              3 Months
            </td> 

            <td className="px-3 py-2">
              <Checkbox
                checked={selectedLeagues[some.id]}
                onChange={() => {
                  leaguesUpdated(selectedLeagues, leagueId)
                  if(selectedLeagues[some.id]){
                    setSelectedLeagues({
                      ...selectedLeagues,
                      [some.id]: false
                    })
                  }
                  else{
                    setSelectedLeagues({
                      ...selectedLeagues,
                      [some.id]: true,
                    });
                  }
                }}
                className="group block size-4 rounded border data-[checked]:bg-Secondary data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 data-[checked]:data-[disabled]:bg-gray-500"
              >
                <svg
                  className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M3 8L6 11L11 3.5"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Checkbox>
            </td>
          </tr>
        ))}
      </table>
      {events && 
      <>
      {/* <h1 className="font-bold text-[24px] ml-[10px] mt-[2rem]">Event</h1>
      <table className=" whitespace-nowrap mt-[1rem] rounded-[16px] text-white rockwell m-auto bg-Primary ">
        <thead className=" text-[15px] font-medium ">
          <tr className="sticky top-0 rounded-lg font-[400] text-[12px] ">
            <th className="px-4 py-3.5 text-start">Home Team </th>
            <th className="px-4 py-3.5 text-start"> Away Team</th>
            <th className="px-4 py-3.5 text-start">Start date</th>
          </tr>
        </thead>
        {events.map((some) => (
          <tr key={some.id}>
            <td className="px-4 py-2 items-center flex gap-[10px] text-[9px] font-[700]">
              {`${some.home_team} Vs ${some.away_team}`}
            </td>
            <td className="px-3 py-2 font-[400] text-[9px] text-center">
              {`${new Date(some.start_datetime).toDateString()}`}
            </td>
            <td className="px-3 py-2  font-[400] text-[9px] text-center">
               {`${new Date(some.start_datetime).toLocaleTimeString()}`}
            </td>
            <td className="px-3 py-2">
              <Checkbox
                checked={true}
                onChange={() => {}}
                className="group block size-4 rounded border bg-Secondary data-[checked]:bg-Primary data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 data-[checked]:data-[disabled]:bg-gray-500"
              >
                <svg
                  className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M3 8L6 11L11 3.5"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Checkbox>
            </td>
          </tr>
        ))}
      </table> */}
    </>}
      <div className="flex flex-col items-center justify-center gap-[10px]">
        <h1 className="text-center font-[400] text-[12px] rockwell py-[8px] ">
          Pool ID: {poolId}
        </h1>

      
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Event;
