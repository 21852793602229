import React from "react";
import { Link } from "react-router-dom";
import Button from "../ReusableComponent/Button";

function Buttons() {
  return (
    <div>
      <div>
        <Link to="/Login">
          <Button
            text="LOGIN"
            classname=" bg-white bebas items-center flex justify-center rounded-[10px] h-[42px] w-[150px] font-[500] text-[26px] text-Primary"
          />
        </Link>
      </div>
      <br />
      <div>
        <Link to="/Register">
          <Button
            text="REGISTER"
            classname=" bg-white bebas items-center flex justify-center rounded-[10px] h-[42px] w-[150px] font-[500] text-[26px] text-Primary"
          />
        </Link>
      </div>
    </div>
  );
}

export default Buttons;
