import React from 'react'

export default function Button({classname, icon, text, onClick}) {
  return (
    <div>
      <button 
      onClick={onClick}
      className={`${classname} text-center`}>
        <div className='flex gap-[10px] items-center justify-center'>
            {icon}
            {text}
        </div>
      </button>
    </div>
  )
}
