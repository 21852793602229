import React from 'react'
import LogoContainer from '../Landing Page/LogoContainer'
import Buttons from '../Landing Page/Buttons'

function landing() {
  return (
    <div className=' background h-screen flex  flex-col  gap-[30px] items-center justify-center '>
      <LogoContainer />
      <Buttons />
    </div>
  )
}

export default landing
