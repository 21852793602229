import React from "react";
import LogoHeader from "../JoinPoolConfirmation/LogoHeader";
import TextSection from "../JoinPoolConfirmation/TextSection";
import Footer from "../ReusableComponent/Footer";

function JoinPoolConfirmation() {
  return (
    <div className="background2">
      <LogoHeader />
      <div className="bg-white rounded-t-[20px] mt-[50px]">
        <TextSection />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default JoinPoolConfirmation;
