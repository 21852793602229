import React from 'react'
import Logo from "../../Assets/Logo.png";
import Button from "../ReusableComponent/Button";
import { useNavigate } from 'react-router-dom';

export default function NavButtons() {

  const navigate = useNavigate();

    const logout = () => {
        localStorage.clear();
        navigate("/");
    }

  return (
    <div className="flex justify-between">
      <img src={Logo} alt="" className="h-[50px] w-[50px] p-[10px]" />
      <div className="flex flex-row items-end justify-end">
        <Button
          text="Connect Wallet"
          classname="bg-Secondary rockwell text-[8px] font-[400] px-[18px] pt-[8px] pb-[4px] rounded-[5px] mr-[10px]"
        />
        <Button
            onClick={logout}
          text="Logout"
          classname="bg-Secondary rockwell text-[8px] font-[400] px-[18px] pt-[8px] pb-[4px] rounded-[5px] mr-[10px]"
        />
      </div>
    </div>
  );
}
