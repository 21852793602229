import React, { useEffect, useState } from "react";
import { Checkbox } from "@headlessui/react";
import Button from "../ReusableComponent/Button";

export const emptyfunc = ()=>{}
function TimeFrame({timeframeUpdate, startTimeUpdated, endTimeUpdated}) {
  const [enabled, setEnabled] = useState("entire");

  return (
    <div>
      <h1 className="text-[24px] font-[400] mt-[30px]  bebas ml-[10px]">
        TIME FRAME
      </h1>
      <div className="flex  text-[12px] font-[400] rockwell items-center justify-evenly mt-[16px]">
        <div className=" flex flex-col gap-[13px]">
          <div className="flex  items-center gap-[10px] ">
            <Checkbox
              title="entire"
              checked={enabled === "entire"}
              onChange={() => {
                timeframeUpdate("entire");
                setEnabled("entire");
              }}
              className="group block size-4 rounded border bg-Primary data-[checked]:bg-Secondary data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 data-[checked]:data-[disabled]:bg-gray-500"
            >
              <svg
                className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M3 8L6 11L11 3.5"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Checkbox>
            <p>Entire Tournment</p>
          </div>
          <div className="flex  items-center gap-[10px]">
            <Checkbox
              title="today"
              checked={enabled === "today"}
              onChange={() => {
                timeframeUpdate("today");
                setEnabled("today");
              }}
              className="group block size-4 rounded border bg-Primary data-[checked]:bg-Secondary data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 data-[checked]:data-[disabled]:bg-gray-500"
            >
              <svg
                className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M3 8L6 11L11 3.5"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Checkbox>
            <p>Today</p>
          </div>
        </div>
        <div className="flex flex-col gap-[13px]">
          <div className="flex  items-center gap-[10px]">
            <Checkbox
              title="week"
              checked={enabled === "week"}
              onChange={() => {
                timeframeUpdate("week");
                setEnabled("week");
              }}
              className="group block size-4 rounded border bg-Primary data-[checked]:bg-Secondary data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 data-[checked]:data-[disabled]:bg-gray-500"
            >
              <svg
                className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M3 8L6 11L11 3.5"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Checkbox>
            <p>This Week</p>
          </div>
          <div className="flex  items-center gap-[10px]">
            <Checkbox
              title="month"
              checked={enabled == "month"}
              onChange={() => {
                timeframeUpdate("month");
                setEnabled("month");
              }}
              className="group block size-4 rounded border bg-Primary data-[checked]:bg-Secondary data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 data-[checked]:data-[disabled]:bg-gray-500"
            >
              <svg
                className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M3 8L6 11L11 3.5"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Checkbox>
            <p>This Month</p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-evenly mt-[16px] p-[5px]">
        <label>Start Date:</label>
        <input
          type="date"
          onChange={(value) => {
            startTimeUpdated(value);
            setEnabled(" ");
          }}
          text="Start date"
          classname="bg-Primary text-white h-[39px] w-[140px] text-[14px] font-[700] rockwell rounded-[8px] "
        />
        <label>End Date:</label>
        <input
          type="date"
          onChange={(value) => {
            endTimeUpdated(value);
            setEnabled(" ");
          }}
          text="End date"
          classname="bg-Primary text-white h-[39px] w-[140px] text-[14px] font-[700] rockwell rounded-[8px] "
        />
      </div>
    </div>
  );
}

export default TimeFrame;
