import React, { useEffect, useState } from 'react'
import SquareContainer from '../ReusableComponent/SquareContainer'
import { featuredPool } from '../../Api';

function FeaturedPools({}) {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("sessionToken")
  );
  const [pools, setPools] = useState([]);

  useEffect(() => {
    async function collectData() {
      const featuredPoolResponse = await featuredPool(authToken);

      if (featuredPoolResponse.success) {
        setPools(featuredPoolResponse.response);
      } else {
        console.log("No Pool Fetched");
      }
    }
    collectData();
  }, [authToken]);

  return (
    <div>
      <div>
      <h1 className='font-[400] bebas text-[24px] p-[5px] mt-[20px]'>Featured Pools</h1>
      <div className="flex justify-evenly mt-[20px] overflow-x-scroll ">
          {pools.map((pool) => {
            return (
              <SquareContainer
                tournamentId={pool.tournament_id}
                text={pool.name}
                className=" m-[5px] text-Primary text-[30px] "
              />
            );
          })}
          {/* <SquareContainer  text='MASTERS' className=' text-Primary text-[30px] '/>
        <SquareContainer  text='NFL' className=' text-Primary'/>
        <SquareContainer  text='PREMIER LEAGUE' className=' text-Primary text-center'/> */}
        </div>
      </div>
    </div>
  );
}

export default FeaturedPools
