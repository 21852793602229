import React from "react";
import Button from "../ReusableComponent/Button";
import Logo from "../../Assets/Logo.png";

function HeaderContainer() {
  return (
    <div>
      <div className="flex flex-row-reverse">
        <Button
          text="Connect Wallet"
          classname=" bg-Secondary text-Primary rounded-[8px] px-[14px] py-[4px] text-[12px] 
          mt-[10px] mr-[10px] font-[500]"
        />
      </div>
      <br />
      <div>
        <img
          src={Logo}
          alt=""
          className=" h-[100px] w-[100px] m-auto mt-[20px] "
        />
      </div>
      <div>
        <h3 className="font-[400] text-white bebas text-center pb-[20px] mt-[20px]">
          CREATE A PROFILE
        </h3>
      </div>
    </div>
  );
}

export default HeaderContainer;
