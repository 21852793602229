import React from 'react'
import Logo from "../../Assets/Logo.png";
import Button from "../ReusableComponent/Button";
import NavButtons from '../JoinPool/NavButtons';



function LogoHeader() {
  return (
    <div>
      <NavButtons/>        
      <div>
        <img src={Logo}   className='h-[60px] w-[60px] m-auto mt-[30px]' />
      </div>
    </div>
  )
}

export default LogoHeader
