import React, { useEffect, useState } from "react";
import Logo from "../../Assets/Logo.png";
import LogoInCircle from "../../Assets/LogoInCircle.png";
import { getUserDetails } from "../../Api";

function ReausableUsernameContainer() {

  const [authToken, setAuthToken] = useState(
    localStorage.getItem("sessionToken")
  )

  const [userDetails, setUserDetails] = useState({
    userName: "",
    caps: 0,
    strikeRate: 0,
    tournaments: 0,
  });

  useEffect(()=>{
    const getDetails = async () => {
      if(authToken){
        
      let userDetail = await getUserDetails(authToken)
      if(userDetail.success){
        userDetail = userDetail.response;
        setUserDetails({
          userName: userDetail.username,
          caps: userDetail.caps,
          strikeRate: userDetail.strike_rate,
          tournaments: userDetail.tournaments,
        });
      }else{
        console.log("No User Details Fetched");
        alert("User Details Fetch Failed!");
      }
      
      }
    }
    getDetails();
    
  }, [authToken])

  return (
    <div>
      
      <div className="flex justify-evenly items-center">
        <div>
          <img src={LogoInCircle} alt="" className="h-[100px] w-[100px]" />
        </div>
        <div className="flex flex-col gap-[2px] font-[500] bebas text-white">
          <h1 className="text-[28px] font-[400px]">{userDetails.userName}</h1>
          <div className="text-[18px] font-[400] bebas">
            <h3>Caps: {userDetails.caps}</h3>
            <h3>STRIKE RATE: {userDetails.strikeRate}</h3>
            <h3>TOURNMENT: {userDetails.tournaments}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReausableUsernameContainer;
