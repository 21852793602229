import React, { useEffect, useState } from "react";
import HeaderContainer from "../JoinPool/HeaderContainer";
import FeaturedPool from "../CfHomePage/FeaturedPools";
import Events from "../JoinPool/Events";
import { getLeagues } from "../../Api";
import Sports from "../CreatePool/Sports";
import NavButtons from "../JoinPool/NavButtons";
import { useNavigate } from "react-router-dom";
function JoinPool() {

  const [leagues, setLeagues] = useState([])
  const navigate = useNavigate()

  useEffect(()=>{

    const sessionToken = localStorage.getItem("sessionToken");
    if(!sessionToken){
      navigate("/Login");
    } 

    const fetchDatas = async()=>{
      const leagueResponse = await getLeagues();

    }

    fetchDatas();
  }, [])


  return (
    <div className="background1">
      <NavButtons/>
      <HeaderContainer />
      <div className="bg-white rounded-t-[16px] mt-[50px] ">
      <h1 className="font-[400] text-center text-[26px] bebas pt-[20px]">Find a pool</h1>
      <FeaturedPool />
        <Sports unclickable={true}/>
        <Events events={leagues}/>
      </div>
    </div>
  );
}

export default JoinPool;
