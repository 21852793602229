import React from 'react'
import Button from '../ReusableComponent/Button'
import { Link } from 'react-router-dom'

export default function Buttons() {
  return (
    <div>
      <div className='flex justify-evenly items-center  bebas  py-[30px]'>
        <Link to ="/Join">
        <Button text="JOIN"  classname='bg-Primary items-center flex justify-center rounded-[10px]  h-[42px] w-[150px] font-[400] text-[24px] text-Secondary' />
        </Link>
        <Link to ="/Create" >
        <Button text="CREATE" classname='bg-Primary items-center flex justify-center rounded-[10px]  h-[42px] w-[150px] font-[400] text-[24px] text-Secondary'  />
        </Link>
      </div>
    </div>
  )
}
