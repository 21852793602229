import React, { useEffect, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import { featuredPool } from "../../Api";
import { Link } from "react-router-dom";

function Events({}) {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("sessionToken")
  );
  const [pools, setPools] = useState([]);

  useEffect(() => {
    async function collectData() {
      const featuredPoolResponse = await featuredPool(authToken);

      if (featuredPoolResponse.success) {
        setPools(featuredPoolResponse.response);
      } else {
        console.log("No Pool Fetched");
      }
    }
    collectData();
  }, [authToken]);
  return (
    <div>
      <div>
        <h1 className="font-bold text-[24px] p-[5px]  mt-[30px]">Events</h1>
        <div className=" bg-Primary text-white my-[10px] rockwell rounded-[16px] mx-[20px] ">
          <table className="w-full whitespace-nowrap  ">
            <thead className=" text-[12px] font-[400] ">
              <tr className="sticky top-0 rounded-lg font-[400] ">
                <th className="px-4 py-3.5 text-start">Soccer Pools</th>
                <th className="px-4 py-3.5 text-start">Start Date</th>
                <th className="px-4 py-3.5 text-start">Players</th>
                <th className="px-4 py-3.5 text-start">Entry</th>
              </tr>
            </thead>
            {pools.map((pool) => (
                <tr
                  className="text-[9px] font-[700] leading-5"
                  key={pool.id}
                  
                >
                  <td className="px-4 py-2 items-center flex ">
                    <Link to={`/Join/JoinPool/${pool.tournament_id}`}>
                      {pool.name}
                    </Link>
                  </td>
                  <td className="px-4 py-2 font-[400] text-[9px]"> {pool.start_time}</td>
                  <td className="px-4 py-2  font-[400] text-[9px]">
                    {pool.player_count}
                  </td>
                  <td className="px-4 py-2 font-[400] text-[9px]">Free</td>
                </tr>
            ))}
          </table>
          <div>
            <TiArrowSortedDown className="text-[26px] m-auto" />
          </div>
        </div>
        <p className="text-center mt-[10px] text-[9px] rockwell pb-[80px] font-[5400]">
          {" "}
          click on the pool name to join
        </p>
      </div>
    </div>
  );
}

export default Events;
