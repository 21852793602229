import React from "react";
import { Link, useNavigate } from "react-router-dom";

function SquareContainer({ className, text, textstyle, tournamentId, unclickable = false }) {

  const navigate = useNavigate();

  return (
    unclickable ?  
      <div
        className={`${className} flex items-center bebas  py-[30px] text-center justify-center bg-Secondary min-w-[100px]  rounded-[10px] shadow-md shadow-[#6f6f6f]`}
      >
        <h1 className={`${textstyle} p-[5px] font-[400] text-[14px]`}>{text}</h1>
      </div>
   : <Link to={`/Join/JoinPool/${tournamentId}`}>
      <div
        className={`${className} flex items-center bebas  py-[30px] text-center justify-center bg-Secondary min-w-[100px]  rounded-[10px] shadow-md shadow-[#6f6f6f]`}
      >
        <h1 className={`${textstyle} p-[5px] font-[400] text-[14px]`}>{text}</h1>
      </div>
    </Link>
  );
}

export default SquareContainer;
