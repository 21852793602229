import React, { useState } from 'react'
import Button from '../ReusableComponent/Button'
import { emptyfunc } from './TimeFrame'

function InputeField({ typeUpdated, poolNameUpdated }) {

  const [entryType, setEntryType] = useState({});
  
  return (
    <div>
      <div className=" flex justify-center mt-[30px]">
        <form action="">
          <input
            onChange={(e)=>{
              poolNameUpdated(e.target.value)
            }}
            type="text"
            placeholder="POOL NAME"
            className="border-Secondary border bebas text-white text-center placeholder:text-center placeholder:font-semibold bg-Primary rounded-[8px] py-[2px] placeholder:text-[#e6e6e6]"
          />
        </form>
      </div>
      <div className="flex  items-center justify-evenly mt-[26px] text-[24px] text-center font-[400] bebas">
        <Button
          onClick={() => {
            typeUpdated(entryType);
            setEntryType({
              open: true,
              invite_only: false,
            });
          }}
          text="OPEN"
          classname={`${
            entryType["open"]
              ? "bg-Secondary h-[42px] w-[150px] rounded-[10px] text-white"
              : "bg-Primary h-[42px] w-[150px] rounded-[10px] text-white"
          }`}
        />

        <Button
          onClick={() => {
            typeUpdated(entryType);
            setEntryType({
              open: false,
              invite_only: true,
            });
          }}
          text="INVITE ONLY"
          classname={`${
            entryType["invite_only"]
              ? "bg-Secondary h-[42px] w-[150px] rounded-[10px] text-white"
              : "bg-Primary h-[42px] w-[150px] rounded-[10px] text-white"
          }`}
        />
      </div>
    </div>
  );
}

export default InputeField
