import React from "react";
import ReusableUsernameContainer from "../ReusableComponent/ReausableUsernameContainer";
import Tournments from "../CurrentTournment/Tournments";
import Footer from "../ReusableComponent/Footer";
import NavButtons from "../JoinPool/NavButtons";

function CurrentTournment() {
  return (
    <div className="background1">
      <NavButtons/>
      <ReusableUsernameContainer />
      <div className="bg-white rounded-t-[20px] mt-[40px] pb-[20px]">
        <Tournments />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default CurrentTournment;
