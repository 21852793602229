import axios from "axios";

export const apiEndPoint = "https://api.championfan.co/";
// export const apiEndPoint = "http://127.0.0.1:8000/";

export const convertTimestampToDateTime = (timestamp, splitText=".") => {
    return new Date(timestamp).toISOString().split(splitText)[0];
}


export const registerUser = async(details) => {
    try{
        const response = await axios.post(apiEndPoint+"auth/register/", {...details});
        console.log("response",response.data);
        return {sucess: true, response: response.data};
    }catch(err){
        console.log("error on register",err);
        return {success: false, error: err};
    }
}

export const loginUser =  async(details) => {
    try{
        const response = await axios.post(apiEndPoint+"auth/login/",{...details});
        console.log("response",response);
        return {sucess: true, response: response.data};
    }catch(err){
        console.log("error on login",err);
        return {success: false, error: err};
    }

}

export const getSports = async() => {
    try{
        const response = await axios.get(apiEndPoint + "sync/sport-list/");
        console.log("response", response.data);
        return {success:true, response: response.data};
    }catch(err){
        console.log("error on fetch sports", err);
        return {success: false, error: err};

    }
}

export const getLeagues = async() => {
    try{
        const response = await axios.get(apiEndPoint + "sync/league-list/");
        console.log("response", response.data);
        return {success:true, response: response.data};
    }catch(err){
        console.log("error on fetch leagues", err);
        return {success: false, error: err};
    }
}

export const getEvents = async({startDateTime, endDateTime, leagueId}) => {
    try {

        if(startDateTime == " ") 
            startDateTime = new Date().toISOString().split(".")[0];

        const start_datetime = new Date(startDateTime).toISOString().split(".")[0]
        const end_datetime = new Date(endDateTime).toISOString().split(".")[0];

        const response = await axios.get(apiEndPoint + "sync/sport-events/",{
            params: {
                start_datetime,
                end_datetime,
                league_id: leagueId
            }
        });
        return {success:true, response: response.data};
    } catch (err) {
        console.log("error on fetch events", err);
        return {success: false, error: err};
    }
}

export const createTournamentPool = async(authToken, details) => {
    try{
        //send 
        const response = await axios.post(
          apiEndPoint + "tournament/create/",
          {
            ...details,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        return { sucess: true, reponse: response.date };

    }catch(err){
        console.log("error on create tournament pool", err);
        return {success: false, error: err};
    }
}

export const pickEvents = async(authToken, data)=>{
    try{
        const response = await axios.post(apiEndPoint+"tournament/add-pool-entry/", {
            ...data
        }, {
             headers: {
              Authorization: `Bearer ${authToken}`,
            },
        })
        return {success: true, response: response.data};
    }catch(err){
        console.log("error non pick events", err);
        return {success: false, error: err}
    }
}

export const allTournamentPicks = async(authToken) => {
    try{
        const allTournamentDetails = await axios.get(apiEndPoint+"tournament/list");
        return { sucess: true, data: allTournamentDetails.data };
    }catch(err){
        console.log("error non pick events", err);
        return {success: false, error: err}
    }
}   

export const featuredPool = async(authToken) => {
    console.log("auth token", authToken)
    try{
        const response = await axios.get(apiEndPoint + "tournament/create/",
            {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          
        });
        return {success: true, response: response.data};
    }catch(err){
        console.log("error on getting pools");
        return {success: false, error: err};
    }
}

export const getUserDetails = async(authToken) => {
    console.log("auth token", authToken);
    try{
        const response = await axios.get(apiEndPoint + "auth/me/", {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
        console.log("details", response.data)
        return {success: true, response: response.data};
    }catch(err){
        console.log("error on getting user details");
        return {success: false, error: err};
    }
}