import React, { useEffect, useState } from "react";
import Button from "../ReusableComponent/Button";
import { matchschedule } from "../data";
import { Link, useNavigate } from "react-router-dom";
import { pickEvents } from "../../Api";

function MakeYourPick({ poolDetails }) {

  const navigate = useNavigate()

  const [authToken, setAuthToken] = useState(
    localStorage.getItem("sessionToken")
  );

  const [selection, setSelection] = useState([]);
  const [tournamentId, setTournamentId] = useState(poolDetails.id);

  const [selected, setSelected] = useState({});

  useEffect(() => {
    if(poolDetails){
      console.log("details of the pool", poolDetails);
      setTournamentId(poolDetails.id);
    }
    
  }, [poolDetails]);


  const submitSelection = () => {
    async function submit(){
      console.log("detail", {
        tournament_id: tournamentId,
        picks: selection,
      });
      const pickEventsSubmitResponse = await pickEvents(authToken, {
        tournament_id: tournamentId,
        picks: selection
      })
      if(pickEventsSubmitResponse.success){
        alert("Picked Success")
        navigate("/Profile")
      }else {
        alert("Failure  on Submission", pickEventsSubmitResponse.error)
      }
    }
    submit();
    
  }

  const onSelect = (event_id, index, pick) => {
    setSelected({
      ...selected,
      [event_id]: pick
    })
    const oldList = selection;
    let newData = { index, pick, event_id };
    let existingIndex = selection.findIndex((data) => data.index == index);
    console.log("existing index", existingIndex);
    if (existingIndex >= 0) {
      oldList[existingIndex] = newData;
      setSelection(oldList);
    } else {
      oldList.push(newData);
      setSelection(oldList);
    }

    console.log("new list is", oldList);
  };

  return (
    <div className=" bg-[#E6E6E6]  py-[16px]">
      <div>
        <h1 className="text-center font-[400] bebas text-[24px]">
          MAKE YOUR PICK
        </h1>
      </div>
      {poolDetails.sport_event?.map((match, i) => (
        <div className="bg-[#E6E6E6] py-[28px]">
          <div className=" flex justify-evenly">
            {/* <div className="h-[36px] w-[36px]">{match.HomeImage}</div> */}
            <div className="flex gap-[20px] text-center  items-center justify-center">
              <div className="font-400 bebas text-[16px]">
                {match.home_team}
              </div>
              <h1 className="font-[700] text-[12px]">VS</h1>
              <div className="font-400 bebas text-[16px]">
                {match.away_team}
              </div>
            </div>
            {/* <div className="h-[36px] w-[36px]">{match.AwayImage}</div> */}
          </div>
          <div className="flex justify-center">{match.start_datetime}</div>
          <div className="flex items-center justify-evenly bg-white shadow-lg w-[96%] m-auto p-[10px] font-[400] text-[14px] bebas mt-[30px] rounded-[16px]">
            <Button
              onClick={() => {
                onSelect(match.event_id, i, "HOME");
              }}
              text="HOME"
              classname={`text-white bg-Primary ${
                selected[match.event_id] == "HOME" ? "bg-Secondary" : " "
              } h-[30px] w-[80px] rounded-[5px]`}
            />
            <Button
              onClick={() => {
                onSelect(match.event_id, i, "DRAW");
              }}
              text="DRAW"
              classname={`text-white bg-Primary ${
                selected[match.event_id] == "DRAW" ? "bg-Secondary" : " "
              } h-[30px] w-[80px] rounded-[5px]`}
            />
            <Button
              onClick={() => {
                onSelect(match.event_id, i, "AWAY");
              }}
              text="AWAY"
              classname={`text-white bg-Primary ${
                selected[match.event_id] == "AWAY" ? "bg-Secondary" : " "
              } h-[30px] w-[80px] rounded-[5px]`}
            />
          </div>
        </div>
      ))}
      <div className="flex justify-center items-center">
        <Button
          onClick={submitSelection}
          text="Submit Picks"
          classname="bg-Secondary px-[18px] pt-[8px] pb-[4px] rounded-[5px] mb-[70px] font-[400] text-[12px] rockwell text-Primary"
        />
      </div>
    </div>
  );
}

export default MakeYourPick;
