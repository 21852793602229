import React from "react";
import Button from "./Button";
import { Scoredata } from "../data";
import { Link } from "react-router-dom";

function ReusableTrournmentContainer({ name, players, tournamentId }) {
  return (
    <div>
      <div className="background2  rounded-[16px] flex justify-evenly py-[20px] items-center">
      <div className=" flex flex-col gap-[20px] ">
          <div>
            <h1 className="font-[400] text-[24px] bebas text-white ">{name}</h1>
          </div>
          <div className="text-white text-[13px] font-[700] rockwell">
            <h3>Rank:1/{players.length}</h3>
            <h3>points: 0</h3>
            <h3>Strike-rate: 0%</h3>
            <h3>Players: {players.length}</h3>
          </div>
          <div>
            <Link
            to={`/Join/JoinPool/${tournamentId}/Select`}
            >
              <Button
                text="Make Picks"
                classname="bg-Secondary  text-Primary px-[18px] pt-[4px] pb-[4px]  text-[12px] rockwell rounded-[8px] font-[400]"
              />
            </Link>
          </div>
        </div>

        <div className="bg-[#fafafa] rounded-[10px] mt-[20px] py-[14px] p-[3px]">
          <h1 className="text-center mb-[10px] text-[14px]  font-[400]">
            Leaderboard
          </h1>
          <table className="w-full whitespace-nowrap ">
            {players.map((player, index) => (
              <tr className="text-[10px] rockwell font-[300px] ">
                <td className="px-4 py-[2px]">{index + 1}</td>
                <td className="px-4 py-[2px]">{player.username}</td>
                <td className="px-4 py-[2px]">0</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
}

export default ReusableTrournmentContainer;
