import React from "react";
import Button from "../ReusableComponent/Button";

function HeaderContainer() {
  return (
    <div>
      <div className="flex flex-col items-center mt-[80px] px-[6.5rem]">
        <form action="">
          <input
            type="text"
            placeholder="ENTER POOL ID"
            className=" border text-white text-center border-Secondary w-[220px] rounded-[8px] placeholder:text-center bebas placeholder:font-bold bg-Primary p-[4px] placeholder:text-[#bbbbbb]"
          />
        </form>
        <div className="self-end">
          <Button
            text="Join Pool"
            classname="bg-Secondary mt-[30px] px-[24px] py-[3px]  bebas rounded-[6px] font-medium   "
          />
        </div>
      </div>
    </div>
  );
}

export default HeaderContainer;
